import config from "../config/config"
import axios from "../util/axios";
import commonApi from "@/api/commonApi";

/**
 * util主要做缓存数据存取
 */
export default {
    /**
     * 从其它页面跳转到列表页面，获取上次保存的查询条件
     */
    listGetParams() {
        let data = sessionStorage.getItem(window.location.pathname);
        if (data) {
            return JSON.parse(data);
        } else {
            return {};
        }
    },
    /**
     * 清空缓存的参数
     * @param path
     */
    clearListParam(path) {
        sessionStorage.removeItem(path);
    },
    /**
     * 从列表页面跳转到详情页面，在session中保存当前查询条件
     * @param params
     */
    beforeList2Detail(params) {
        sessionStorage.setItem(window.location.pathname, JSON.stringify(params));
    },
    /**
     * 当table列表有selection时，选择某一行，让这条数据被选中, 这个事件对应table的clickRow
     * @param column 判断的字段名
     * @param rowData 点击哪一行
     * @param selectionData selection的数据
     * @param tableData 列表的数据
     * @param ref table的ref
     */
    tableSelectionRowClick(column, rowData, selectionData, tableData, ref) {
        // 这条数据当前是被选中还是没有被选中
        let selected = false;
        for (let i = 0; i < selectionData.length; i++) {
            if (rowData[column] === selectionData[i][column]) {
                selected = true;
                break;
            }
        }
        // 被选中
        for (let i = 0; i < tableData.length; i++) {
            if (rowData[column] === tableData[i][column]) {
                ref.toggleRowSelection(tableData[i], !selected);
                break;
            }
        }
    },
    // 导出excel,name可为空，如果指定name，需要指定文件类型
    exportExcel(url, params, name) {
        // 把参数转成params
        let query = "";
        for (let key in params) {
            query += "&" + key + "=" + params[key];
        }
        query = query.replace("&", "");
        let token = this.getHeaderToken();
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = `${config.baseUrl}${url}?${query}&token=${token}`;
        if (name) {
            link.setAttribute("download", name);
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    // 获取当前的运行环境,1web端，2企业微信端，3企业微信手机端, 4
    getContextPath() {
        if (sessionStorage.getItem("platform") === 'pda' > 0) {
            return 4;
        }
        if (navigator.userAgent.indexOf("Mobile") > 0) {
            return 3;
        }
        if (navigator.userAgent.indexOf("WeChat") > 0) {
            return 2;
        }
        return 1;
    },
    // 是否已购买企业微信账号，是否开通外部联系人
    getQywxAccountType() {
        return new Promise(resolve => {
            this.getUserInfo().then(user => resolve(user.qywxAccountType));
        })
    },
    // 获取行政区划字典,这个需要同步获取
    async getAdministrativeArea() {
      let cached = this.getSession("administrativeArea");
      if (!cached) {
          // 没有数据，获取数据并缓存
          const data = await axios.get({url: "/basedata/common/queryAdministrativeArea"});
          // 缓存数据
          this.setSession("administrativeArea", JSON.stringify(data));
          return data;
      } else {
          return JSON.parse(cached);
      }
    },
    // 获取系统配置项，这个需要同步获取
    async getSystemSetting() {
        return new Promise(resolve => {
            let cached = this.getSession("systemSetting");
            if (!cached) {
                // 没有数据，获取数据并缓存
                axios.get({url: "/basedata/common/getSetting"}).then(data => {
                    // 把获取到的data转成对象
                    let result = {};
                    if (data.length > 0) {
                        data.forEach(d => result[d.settingCode] = d.settingValue)
                    }
                    // 缓存数据
                    this.setSession("systemSetting", JSON.stringify(result));
                    resolve(result);
                });
            } else {
                resolve(JSON.parse(cached)) ;
            }
        })
    },
    // 获取全部的职员列表
    async getAllEmployess() {
        let cached = this.getSession("employees");
        if (!cached) {
            // 没有数据，获取数据并缓存
            const data = await axios.get({url: "/basedata/common/queryAllEmployees"});
            // 缓存数据
            this.setSession("employees", JSON.stringify(data));
            return data;
        } else {
            return JSON.parse(cached);
        }
    },
    // 获取全部的角色列表
    async getAllRoles() {
        let cached = this.getSession("roles");
        if (!cached) {
            // 没有数据，获取数据并缓存
            const data = await axios.get({url: "/basedata/common/queryAllRoles"});
            // 缓存数据
            this.setSession("roles", JSON.stringify(data));
            return data;
        } else {
            return JSON.parse(cached);
        }
    },
    // 请求去重,某些请求需要去重，避免客户双击引起数据重复
    async distinct(url, timeout) {
        return new Promise((resolve, reject) => {
            let name = "distinctUrl";
            // 缓存的数据
            let ses = sessionStorage.getItem(name);
            // 时间间隔，默认3秒一次
            if (!timeout) {
                timeout = 3000
            }
            if (!ses) {
                // 数据不存在，添加数据，执行函数
                sessionStorage.setItem(name, url + ";" + new Date().getTime());
                resolve();
            } else {
                // 数据存在，看是否过了时间
                let data = ses.split(";");
                if (url === data[0]) {
                    // 是url，看时间
                    if (new Date().getTime() - data[1] > timeout) {
                        // 缓存的不是这个url，执行
                        sessionStorage.setItem(name, url + ";" + new Date().getTime());
                        resolve();
                    } else {
                        reject();
                    }
                } else {
                    // 缓存的不是这个url，执行
                    sessionStorage.setItem(name, url + ";" + new Date().getTime());
                    resolve();
                }
            }
        });
    },
    // 获取token
    getHeaderToken() {
        let token = this.findSessionHeader();
        if (!token) {
            token = this.findCookieHeader();
        }
        return token.token;
    },
    // 获取HTTP请求头
    findSessionHeader() {
        if (this.getSession("grantedToken")) {
            let grantedToken = this.getSession("grantedToken")
            return {grantedToken}
        } else if (this.getSession("account")) {
            let token = JSON.parse(this.getSession("account")).token;
            if (token) return {token}
        }
        return false
    },
    findCookieHeader() {
        let token = this.getCookie("token")
        if (token) return {token}
        return false
    },
    // 清空session
    clearCacheData() {
        sessionStorage.removeItem("indexedDb");
    },
    clearSession() {
        // 清理session,会退出登录
        sessionStorage.clear();
    },
    setUserInfo(userInfo) {
        sessionStorage.setItem("account", JSON.stringify(userInfo));
    },
    // 在session中存放、获取、删除缓存数据
    setSession(name, val) {
        sessionStorage.setItem(name, val)
    },
    async getUserInfo() {
        return new Promise((resolve) => {
            let str = this.getSession("account");
            // session中没有登录信息
            if (str) {
                resolve(JSON.parse(str));
                return;
            }
            commonApi.getUserInfo().then(user => {
                this.setSession("account", JSON.stringify(user));
                resolve(user);
            })
        })
    },
    getSession(name) {
        if (sessionStorage.getItem(name)) return sessionStorage.getItem(name)
        return "";
    },
    delSession(name) {
        sessionStorage.removeItem(name);
    },
    /**
     *
     * @param name
     * @param value
     * @param exdays 缓存多少天
     */
    setCookie(name, value, exdays) {
        if (exdays) {
            let exp = new Date(); //设置Cookie过期日期
            exp.setTime(exdays * 24 * 60 * 60 * 1000 + exp.getTime());
            //添加Cookie
            document.cookie = name + "=" + value + ";expires=" + exp.toGMTString();
        } else {
            document.cookie = name + "=" + value + ";";
        }

    },
    getCookie(name) {
        // name = config.systemCode + name;
        let cname = config.systemCode + name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(cname) != -1) return c.substring(cname.length, c.length);
        }
        return "";
    },
    clearCookie(name) {
        let exp = new Date();
        exp.setTime(exp.getTime() - 1);
        let cval = this.getCookie(name);
        if (cval != null)
            document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
    },

    // 获取今天的时间：年/月/日
    getDate() {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let time = year + "/" + month + "/" + day
        return time
    },

    // 时间格式转换 ——> "年/月/日"
    setDate(timer) {
        let date = new Date(timer.replace(/-/g, "/"));
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let time = year + "/" + (month > 9 ? month : '0' + month) + "/" + (day > 9 ? day : '0' + day);
        return time;
    },

    // 比对日期大小 YYYY-MM-DD
    timeComparison(dateA, dateB) {
        let dateA_s = Date.parse(dateA.replace(/\-/g, '/'))
        let dateB_s = Date.parse(dateB.replace(/\-/g, '/'))
        return dateA_s > dateB_s
    },

    // 获取当年第一天
    getFirstDayOfYear() {
        let date = new Date()
        date.setDate(1);
        date.setMonth(0);
        return date;
    },

    // 获取订单状态中文翻译
    getOrderStatusCH(status) {
        const orderStatus = {
            DELETED: {status: -1, name: '已删除'},
            DRAFT: {status: 0, name: '草稿'},
            SUBMIT: {status: 1, name: '已提交'},
            CHECKED: {status: 10, name: '商家确认'},
            Logistic: {status: 20, name: '已发货'},
            RECEIVE: {status: 30, name: '已收货'},
            STOCK: {status: 40, name: '已入库'},
            COMPLETE: {status: 99, name: '已完成'},
            DOING: {status: 100, name: '进行中'},
        }
        let name = ''
        Object.values(orderStatus).forEach((item, key) => {
            if (item.status === status) name = item.name
        })
        return name
    },

    // 获取订单状态中文翻译
    getSysteamName(type) {
        const types = {
            SUPPORTER: {type: 1, name: '供应商管理平台'},
            PLAT: {type: 2, name: '订货管理平台'},
            DISTRIBUTION: {type: 3, name: '分销商管理平台'},
            WHOLESALER: {type: 4, name: '批发商管理平台'},
            STORE: {type: 5, name: '门店管理平台'},
            CUSTOMER: {type: 6, name: '终端客户管理平台'},
        }
        let name = ''
        Object.values(types).forEach((item, key) => {
            if (item.type === type) name = item.name
        })
        return name
    },

    setEditType(arr, children) {
        arr.map(i => {
            i.editType = true;
            if (i[children]) i[children] = this.setEditType(i[children], children)
        })
        return arr
    }
}