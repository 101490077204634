<template>
  <div id="app" style="height: 100%;background: #f5f6f8;overflow: hidden">
    <router-view/>
  </div>
</template>
<script>

import util from "@/util/util";

export default {
  name: "",
  components: {
  },
  data() {
    return {

    };
  },
  watch: {
    $route: {
      handler(to,from) {
        // 监听路由变化，如果上下文页面不是子级到父级，清空缓存的查询条件
        if (from.path.indexOf(to.path) < 0) {
          util.clearListParam(to.path);
        }
      }
    }
  },
  created() {
    // 切换页面主题
    let themeType = util.getCookie("UserPageTheme");
    if (!themeType) {
      themeType = "Dark"
    }
    // 切换主题
    window.document.documentElement.setAttribute( "data-theme", themeType);
  },
  mounted() {
    let this_ = this;
    // 窗口变化
    // window.onresize = () => {
    //   if (window.innerWidth <= 900) {
    //     // 企业微信手机打开
    //     this_.$router.push({path: "/mobile/home"})
    //   } else {
    //     // 企业微信电脑打开
    //     this_.$router.push({path: "/menu"})
    //   }
    // };
  },
  methods: {

  }
};
</script>
<style lang="scss">

* {
  list-style: none;
}

body {
  margin: 0;
  padding: 0 !important;
  overflow: hidden;

}

html, body {
  height: 100%;
}

.content {
  overflow: hidden;
  background-color: #f0f2f5;
}

.defaultViewStyle {
  position: relative;
  border: 1px solid #dcdfe6;
  border-top: 0;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);
  background: #fff;
  padding: 10px 12px;
  box-sizing: border-box;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.defaultMobilePage{
  padding-bottom: 0;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
}
.scrollPage {
  display: block;
  overflow-y: auto;
  height: 100%;
}

.searchArea {
  display: flex;
  justify-content: space-between;

  .el-form:last-of-type {
    .el-form-item {
      margin-bottom: 12px;
    }

    .el-form-item:last-of-type {
      margin-right: 0;
    }
  }
}

.gridView {
  display: flex;
  height: 100%;

  .defaultViewStyle {
    border: 0;
    border-radius: 4px;
    box-shadow: none;
  }

  > div:first-child {
    min-width: 280px;
    max-width: 280px;
    margin-right: 10px;
    padding: 10px 0;
    font-size: 14px;
    position: relative;
  }

  > div:nth-of-type(2) {
    flex: 1;
  }
}

*::-webkit-scrollbar {
  width: 6px;
  height: 1px;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #dadbdd;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  border: 0;
  border-radius: 10px;
  background: #ededed;
}

.el-table .el-table__cell {
  padding: 12px 0 !important;
}

.el-table thead {
  color: #333;
}

.el-table th.el-table__cell {
  background: #fafafa;
}
.el-table thead.is-group th.el-table__cell{
  background: #fafafa;
}

.el-descriptions {
  margin-bottom: 12px;

  .el-descriptions__header {
    margin-top: 10px;
  }

  .el-descriptions-row {
    display: flex;

    th {
      width: 140px;
      display: flex;
      align-items: center;
    }

    td {
      flex: 3;
    }
  }

  .el-descriptions-item__label {
    text-align: center;
    width: 120px;
  }

  .el-form-item {
    margin: 0;
    padding: 0;
  }

  .el-form-item__content {
    margin: 0 !important;
  }

  .el-form-item__error {
    top: calc(50% - 7px);
    left: inherit;
    right: 42px;
  }

  .el-input__inner,
  .el-textarea__inner {
    padding: 0;
    border: 0;
  }

  .is-disabled > .el-input__inner {
    background-color: transparent;
    color: #000;
  }

  .el-select,
  .el-cascader,
  .el-date-editor {
    width: 100%;
  }

  .el-date-editor {
    .el-input__prefix {
      left: inherit;
      right: 5px;
    }
  }
}
pre{
  white-space: pre-wrap;
  font-size: 15px;
  margin: 10px 0;
}
::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner{
  background: #FFF;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner{
  border-color: #409EFF;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
  border-color: #409EFF;
}
</style>
