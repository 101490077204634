<template>
  <a :class="type + ' btn' + (disable? ' a_disabled': '')" @click="clickBtn">
    <slot/>
  </a>
</template>

<script>

// 在table中通用的按钮样式，type和el-button相同
export default {
  props: {
    type: {default: "primary"},
    disable: {default: false},
  },
  name: "",
  components: {
  },
  data() {
    return {

    };
  },
  created() {
  },
  methods: {
    clickBtn() {
      if (!this.disable) {
        this.$emit("click");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.primary{
  color: #409eff;
}
.warning{
  color: #E6A23C;
}
.success{
  color: #67C23A;
}
.danger{
  color: #F56C6C;
}
.info{
  color: #909399;
}
.btn{
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
  margin: 0 5px;
}
.a_disabled{
  cursor: not-allowed;
  color: #AAA;
}
</style>