import Vue from "vue";

export default {
    scroll: {
        bind(el, binding) {
            const select_wrap_dom = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
            select_wrap_dom.addEventListener("scroll", function () {
                const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
                if (condition) binding.value();
            });
        }
    }
}