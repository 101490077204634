<template>
  <div class="confirmbtn">
    <table-button v-if="type === 'tableButton'" @click.native.stop="clickData" type="danger" :disable="disabled">
      <slot></slot>
    </table-button>
    <el-button v-else-if="type === 'elButton'" size="small" @click.native.stop="clickData" type="danger" :disabled="disabled">
      <slot></slot>
    </el-button>
  </div>
</template>

<script>
import TableButton from "@/components/h5/TableButton";
export default {
  emits: ["click"],
  props: {
    // tableButton，对应tableButton组件, elButton，对应el-button组件,风格固定为danger
    type: {default: "tableButton"},
    // 提示消息
    message: {default: "是否确认删除数据"},
    // 提示消息的title
    title: {default: "提示"},
    // 是否禁用
    disabled: {default: false},
  },
  name: "",
  components: {
    TableButton
  },
  data() {
    return {

    };
  },
  created() {
  },
  methods: {
    clickData() {
      this.$confirm(this.message, this.title, {
        type: 'warning'
      }).then(() => {
        this.$emit("click");
      }).catch(() => console.log("取消"));
    }
  }
};
</script>

<style lang="scss" scoped>
.confirmbtn{
  display: inline-block;
}
</style>