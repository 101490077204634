// saas管理平台的路由
let routers = [
    // web端
    {
        path: '/menu', component: () => import('../views/h5/menu/Menu.vue'),
        children: [
            {
                path: "", component: {render: c => c("router-view")},
                children: [
                    // saas管理平台
                    {
                        path: '/saas-merchant',
                        name: "平台商户",
                        component: {render: c => c("router-view")},
                        children: [
                            {path: `/saas-merchant/merchant`, name: '商户管理', component: () => import('../views/saasPlat/platMerchant/Merchant/Merchant')},
                            {path: `/saas-merchant/merchant/detail`, name: '商户管理详情', component: () => import('../views/saasPlat/platMerchant/Merchant/Detail')},
                            {path: `/saas-merchant/order`, name: '账号订单-管理平台', component: () => import('../views/saasPlat/platMerchant/AccountOrder/AccountOrder')},
                            {path: `/saas-merchant/order/detail`, name: '账号订单详情', component: () => import('../views/saasPlat/platMerchant/AccountOrder/AccountDetail')},
                        ]
                    },
                    {
                        path: '/saas-manage',
                        name: "平台服务",
                        component: {render: c => c("router-view")},
                        children: [
                            {path: `/saas-manage/menu`, name: '菜单管理', component: () => import('../views/saasPlat/platService/menuManage/MenuManage')},
                            {path: `/saas-manage/service`, name: '服务管理', component: () => import('../views/saasPlat/platService/serviceManage/ServiceManage')},
                            {path: `/saas-manage/businessForm`, name: '服务管理', component: () => import('../views/saasPlat/platService/flowBusiness/FlowBusinessList')},

                        ]
                    },
                    {
                        path: '/bi',
                        name: "BI统计",
                        component: {render: c => c("router-view")},
                        children: [
                            {path: `/bi/employee`, name: '职员汇总', component: () => import('../views/h5/bi/employeeBi/EmployeeBi')},
                            {path: `/bi/client`, name: '销售统计', component: () => import('../views/h5/bi/clientBi/ClientBi')},
                        ]
                    },
                ]
            }
        ]
    },

    ];
export default routers;