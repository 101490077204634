// 兼容浏览器
const indexedDB = window.indexedDB || window.webkitIndexedDB ||window.mozIndexedDB||window.msIndexedDB;
export default {
    databaseName: "yqgj",
    databaseVersion: 1,
    db: null,
    // 清空数据
    clear() {
        // 直接删除整个数据库
        indexedDB.deleteDatabase(this.databaseName);
    },
    // 打开数据库连接
    async openCollection(objectName, idColumn) {
        return new Promise((resolve, reject) => {
            try{
                indexedDB.deleteDatabase(this.databaseName);
            } catch (e) {
                console.log("删除数据库报错")
            }
            // 从session中获取上次的version
            this.databaseVersion = JSON.parse(sessionStorage.getItem("indexedDb"));
            // 每一次open version+1,否则onupgradeneeded不会触发
            this.databaseVersion  += 1;
            sessionStorage.setItem("indexedDb", JSON.stringify(this.databaseVersion));
            // 关闭数据库,如果不关闭数据库，将不能触发任何回调
            if (this.db) {
                this.db.close();
            }
            const request = indexedDB.open(this.databaseName, this.databaseVersion);
            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                // 创建一个名为"Users"的数据存储对象，并指定主键字段为"id"
                if (!db.objectStoreNames.contains(objectName)) {
                    db.createObjectStore(objectName, { keyPath: idColumn });
                }
                resolve(db);
            };
            request.onsuccess = (event) => {
                const db = event.target.result;
                // 连接成功，返回db
                resolve(db);
            };
            // 报错
            request.onerror = () => {
                console.log("数据库连接异常")
                reject(request.error);
            }
        });
    },
    async putData(objectName, list) {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(objectName, "readwrite");
            const objectStore = transaction.objectStore(objectName);
            for (let i = 0; i < list.length; i++) {
                let putRequest = objectStore.put(list[i]);
                putRequest.onerror = () => {
                    reject(putRequest.error);
                }
            }
            resolve(list);
        })
    },
    // 添加多条数据，只接受列表
    async put(objectName, idColumn, list) {
        return new Promise((resolve, reject) => {
            if (!list || !list.length) {
                resolve(list);
            }
            if (this.db) {
                this.putData(objectName, list).then(l => resolve(l)).catch(e => reject(e));
            } else {
                this.openCollection(objectName, idColumn).then(db => {
                    this.db = db;
                    this.putData(objectName, list).then(l => resolve(l)).catch(e => reject(e));
                });
            }
        });
    },
    // 读取全部数据
    async getAll(objectName, idColumn) {
        return new Promise((resolve, reject) => {
            if (this.db) {
                // 有可能没有这个库
                if (!this.db.objectStoreNames.contains(objectName)) {
                    // 不存在这个库
                    // 没有连接，创建新的连接
                    this.openCollection(objectName, idColumn).then(db => {
                        this.db = db;
                        this.getAllData(objectName).then(list => resolve(list)).catch(e => reject(e));
                    }).catch(e => reject(e));
                } else {
                    // 有连接，直接读取
                    this.getAllData(objectName).then(list => resolve(list)).catch(e => reject(e));
                }
            } else {
                // 没有连接，创建新的连接
                this.openCollection(objectName, idColumn).then(db => {
                    this.db = db;
                    this.getAllData(objectName).then(list => resolve(list)).catch(e => reject(e));
                }).catch(e => reject(e));
            }
        });
    },
    // 读取数据
    async get(objectName, idColumn, id) {
        return new Promise((resolve, reject) => {
            if (this.db) {
                // 有可能没有这个库
                if (!this.db.objectStoreNames.contains(objectName)) {
                    // 没有连接，创建新的连接
                    this.openCollection(objectName, idColumn).then(db => {
                        this.db = db;
                        // 查询数据
                        this.getData(objectName, id).then(data => resolve(data)).catch(e => reject(e));
                    }).catch(e => reject(e))
                } else {
                    // 有连接,直接查询数据
                    this.getData(objectName, id).then(data => resolve(data)).catch(e => reject(e));
                }

            } else {
                // 没有连接，创建新的连接
                this.openCollection(objectName, idColumn).then(db => {
                    this.db = db;
                    // 查询数据
                    this.getData(objectName, id).then(data => resolve(data)).catch(e => reject(e));
                }).catch(e => reject(e))
            }

        });
    },
    // 读取所有数据
    async getAllData(objectName) {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(objectName, "readonly");
            const objectStore = transaction.objectStore(objectName);
            // 打开游标
            const queryRequest = objectStore.openCursor();
            let list = [];
            queryRequest.onsuccess = (event) => {
                let cursor = event.target.result;
                if (cursor) {
                    // 游标有值
                    list.push(cursor.value);
                    cursor.continue();
                } else {
                    // 游标为空，已经查询完了,返回数据
                    resolve(list);
                }
            }
            queryRequest.onerror = () => {
                console.log("数据查询异常")
                reject(queryRequest.error);
            }
        });
    },
    async getData(objectName, id) {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(objectName, "readonly");
            const objectStore = transaction.objectStore(objectName);
            const queryRequest = objectStore.get(id);
            queryRequest.onsuccess = () => {
                resolve(queryRequest.result);
            }
            queryRequest.onerror = () => {
                console.log("数据查询异常")
                reject(queryRequest.error);
            }
        })
    }
}