import axios from "axios";
import config from "../config/config.js";
import util from "./util";

import {Message} from 'element-ui';
import router from '../router';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// 请求超时时间
axios.defaults.timeout = 1800000;
let cancel = null;
let progressInt = 0;
function startProgress() {
    // 0.1秒以内不显示进度条
    progressInt ++;
    setTimeout(() => {
        if (progressInt > 0) {
            NProgress.start();
        }
    }, 100);

}
function stopProgress() {
    progressInt --;
    NProgress.done();
}
// axios请求拦截器
axios.interceptors.request.use(
    config => {
        let header = {};
        startProgress();

        if (util.findSessionHeader()) {
            header = util.findSessionHeader();
        } else {
            header = util.findCookieHeader();
        }
        if (header.token) {
            config.headers.token = header.token;
            config.headers.systemCode = header.systemCode;
            config.headers.merchantUuid = header.merchantUuid;
        }
        if (header.grantedToken) {
            config.headers.grantedToken = header.grantedToken;
        }
        config.cancelToken = new axios.CancelToken(function (c) {
            cancel = c;
        });
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// axios响应拦截器
axios.interceptors.response.use(
    res => {
        stopProgress();
        // 过滤的白名单
        if (res.config.url.indexOf('/api') !== -1) {
            return Promise.resolve(res);
        } else if (!res.config.url.startsWith(config.baseUrl)) {
            // 访问其它网站
            return Promise.resolve(res);
        } else {
            // 如果是导出
            if (res.config.responseType === "blob") {
                return Promise.resolve(res.data);
            }
            if (res.data.code === '2000') {
                let uri = res.config.url.split("/");
                let lastPath = uri[uri.length - 1];
                // 操作成功
                if (lastPath.endsWith("save") || lastPath.endsWith("Save") || lastPath.startsWith("save")) {
                    Message({
                        message: '保存成功',
                        type: 'success',
                        duration: 2000,
                    });
                }
                if (lastPath.endsWith("delete") || lastPath.endsWith("Delete") || lastPath.startsWith("delete")) {
                    Message({
                        message: '删除成功',
                        type: 'success',
                        duration: 2000,
                    });
                }
                return Promise.resolve(res.data);
            }
            if (!res || res.data.code !== '2000') {
                cancel && cancel()
                if (res.data.code === '3000') {
                    // 未登录或已过期，重新登录
                    let context = util.getContextPath();
                    // 清空登录信息
                    util.clearSession();
                    if (context === 4) {
                        // pda
                        router.push("/pda/login");
                    }else if (context === 2 || context === 3) {
                        let origin = window.location.origin + "/mobile/login";
                        let query = window.location.href;
                        window.location = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.appid}&redirect_uri=${encodeURIComponent(origin)}&response_type=code&scope=snsapi_base&state=${query}#wechat_redirect`;
                    } else {
                        // web打开
                        router.push("/");
                    }
                    return;
                }
                Message({
                    message: '请求异常:' + res.data.message,
                    type: 'warning',
                    duration: 2000,
                });
                throw new Error(res.data.message);
            }
        }
    },
    error => {
        stopProgress();
        cancel && cancel()
        Message({
            message: '请求异常:' + JSON.stringify(error.message),
            type: 'warning',
            duration: 2000,
        });
    }
);

export default {
    get({url, data, baseUrl, responseType}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: url.startsWith("http") || url.startsWith("/api") ? url: (baseUrl? baseUrl + url : config.baseUrl + url),
                params: data,
                responseType: responseType,
            }).then(res => {
                if (res) resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },
    post({url, data, baseUrl}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: url.startsWith("http") ? url: (baseUrl? baseUrl + url : config.baseUrl + url),
                data: data,
            }).then(res => {
                if (res) resolve(res.data)
            }).catch(err => {
                reject(err)
            });
        })
    },
    del({url, data, baseUrl}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'DELETE',
                url: url.startsWith("http") ? url: (baseUrl? baseUrl + url : config.baseUrl + url),
                params: data,
            }).then(res => {
                if (res) resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
};