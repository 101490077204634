import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'font-awesome/css/font-awesome.min.css';
import Calendar from 'vue2-datepick'; //日期控件

Vue.config.productionTip = false
Vue.use(Calendar);//日期控件

/** ElementUI */
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import TableButton from "@/components/h5/TableButton";
import TableList from "@/components/h5/layout/TableList";
import ConfirmBtn from "@/components/ConfirmBtn";
import indexdb from "@/util/indexdb";
// 导入vant样式
import 'vant/lib/index.css'
import {Cell, Picker, DatetimePicker} from 'vant'

Vue.use(Cell);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(ElementUI);
Vue.component("TableList", TableList);
Vue.component("TableButton", TableButton);
Vue.component("ConfirmBtn", ConfirmBtn);
/** remixicon */
import 'remixicon/fonts/remixicon.css'

import directives from "./directives";
Object.keys(directives).forEach(item => {
  Vue.directive(item, directives[item])
})

Date.prototype.format =  function (fmt) {  // author: meizz
  let o = {
    "M+":  this.getMonth() + 1,  // 月份
    "d+":  this.getDate(),  // 日
    "h+":  this.getHours(),  // 小时
    "m+":  this.getMinutes(),  // 分
    "s+":  this.getSeconds(),  // 秒
    "q+": Math.floor(( this.getMonth() + 3) / 3),  // 季度
    "S":  this.getMilliseconds()  // 毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, ( this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for ( let k  in o)
    if ( new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}
// 从数组中移除一个对象，返回新的数组
Array.prototype.remove = function(index) {
  return this.slice(0, index).concat(this.slice(index + 1, this.length));
}
// 两个数组拼接
Array.prototype.pushAll = function(arr) {
  if (arr instanceof Array) {
    for (let i = 0; i < arr.length; i++) {
      this.push(arr[i])
    }
  } else {
    this.push(arr)
  }
}
// 数组中去重添加，可设置回调
Array.prototype.distinctPush = function(data, compares) {
  if (!compares) {
    // 没有设置回调，使用等号
    let contains = false;
    for (let i = 0; i < this.length; i++) {
      if (this[i] === data) {
        // 已经有这个对象了
        contains = true;
        break;
      }
    }
    if (!contains) {
      this.push(data);
    }
  } else {
    // 通过回调判定是否相同
    let contains = false;
    for (let i = 0; i < this.length; i++) {
      if (compares(data, this[i])) {
        // 已经有这个对象了
        contains = true;
        break;
      }
    }
    if (!contains) {
      this.push(data);
    }
  }
}
Array.prototype.contains = function(data) {
  for (let i = 0; i < this.length; i++) {
    if (this[i] === data) {
      return true;
    }
  }
  return false;
}
// 深度拷贝,只能拷贝对象或数组
Vue.prototype.deepClone = function (obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  let result = Array.isArray(obj)? []: {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = this.deepClone(obj[key]);
    }
  }
  return result;
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
