import axios from "../util/axios";

export default {
    /** 行政区划字典 */
    queryAdministrativeArea: () => axios.get({url: "/basedata/common/queryAdministrativeArea"}),
    /** 查询所有职员 */
    queryAllEmployees: () => axios.get({url: "/basedata/common/queryAllEmployees"}),
    /** 查询职员，分页*/
    querySimpleEmployees: (data) => axios.get({url: "/basedata/common/querySimpleEmployees", data: data}),
    /**查询系统配置*/
    getSetting: () => axios.get({url: "/basedata/sys/setting/getSetting"}),
    /** 查询角色可用菜单 */
    getMenu: (systemType) => axios.get({url: "/basedata/common/getMenu", data: {systemType}}),
    /** 企业微信注入流程 signature */
    qywxSignature: (ref) => axios.get({url: `/basedata/common/getSignature?refer=` + ref}),
    /** 企业微信注入 agentSignature */
    qywxAgentSignature: (ref) => axios.get({url: `/basedata/common/getAgentSignature?refer=` + ref}),
    /** 获取系统信息 */
    queryAllService: () => axios.get({url: "/basedata/common/queryAllService"}),
    /**客户目录字典*/
    queryClientCategories: () => axios.get({url: "/basedata/common/queryClientCategories"}),
    /**商品目录字典*/
    queryProductCategories: () => axios.get({url: "/basedata/common/queryProductCategories"}),
    /**查询客户列表*/
    queryClientList: (searchInfo) => axios.get({url: "/basedata/common/queryClientList", data: searchInfo}),
    /**查询客户详情*/
    findClientById: (id) => axios.get({url: "/basedata/common/findClientById", data: {id}}),
    /**查询客户详情*/
    findClientByErpId: (erpId) => axios.get({url: "/basedata/common/findClientByErpId", data: {erpId}}),
    /**修改密码*/
    changePassword: (passwordForm) => axios.post({url: "/basedata/common/changePassword", data: passwordForm}),
    /**查询全部角色*/
    queryAllRoles: () => axios.get({url: "/basedata/common/queryAllRoles"}),
    /**查询全部部门*/
    queryDeparts: () => axios.get({url: "/basedata/common/queryDeparts"}),
    /** 查询全部商品，只返回基础信息*/
    queryAllProductSimple: () => axios.get({url: "/basedata/common/queryAllProductSimple"}),
    /** 分页查询商品，只返回基础信息*/
    queryProductSkuSimple: (searchInfo) => axios.get({url: "/basedata/common/queryProductSkuSimple", data: searchInfo}),
    /**查询商品单位*/
    queryUnits: () => axios.get({url: "/basedata/common/queryUnits"}),
    /** 查询全部客户，只返回基础信息*/
    queryAllClients: () => axios.get({url: "/basedata/common/queryAllClients"}),
    /** 获取erp关联配置*/
    databaseConfig: () => axios.get({url: "/basedata/common/databaseConfig"}),
    /** 获取erp关联配置*/
    getUserInfo: () => axios.get({url: "/basedata/common/getUserInfo"}),
    /**获取自定义流程*/
    queryAllFlows: () => axios.get({url: "/basedata/common/process/queryAllFlows"}),
    /**树形字典*/
    queryDictionTree: (dicType) => axios.get({url: "/basedata/common/queryDictionTree", data: {dicType}}),
    /** 查询职员*/
    findEmployee: (id) => axios.get({url: "/basedata/common/findEmployee", data: {id}}),
    /** 通过erpId查询职员*/
    findEmployeeByErpId: (erpId) => axios.get({url: "/basedata/common/findEmployeeByErpId", data: {erpId}}),

}