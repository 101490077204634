// web端路由
let routers = [
    // web端
    {path: '/test', name: '测试页面', component: () => import("../views/h5/Test")},
    {path: '/screenTest', name: '双屏测试--主屏', component: () => import("../views/h5/test/screenTest/ScreenMain")},
    {path: '/screenTest2', name: '双屏测试--副屏', component: () => import("../views/h5/test/screenTest/ScreenSub")},
    {path: '/divTest', name: 'div缩放', component: () => import("../views/h5/test/divMoveTest/DivTest")},
    {path: '/tableTest', name: 'div缩放', component: () => import("../views/h5/test/tableCustomerTest/TableCustomerTest")},
    {path: `/share/paybill/clientstatement`, name: '对账单明细--客户打开', component: () => import('../views/h5/statement/paybill/ClientStatement')},
    // 宏旺达预览
    {path: `/dzfw/hwd/salePromotionApply/preview`, name: '促销服务协议', component: () => import('../views/h5/dzfw/hwd/salePromotionApply/SalePromotionApplyPreview2')},
    {
        path: '/menu', component: () => import('../views/h5/menu/Menu.vue'),
        children: [
            {
                path: "", component: {render: c => c("router-view")},
                children: [
                    // {path: `/menu`, name: '首页', component: () => import('../views/h5/menu/Home')},
                    {
                        path: '/customer',
                        name: "客户管理",
                        component: {render: c => c("router-view")},
                        children: [
                            {path: `/customer/client`, name: '客户列表', component: () => import('../views/h5/customer/client/ClientList.vue')},
                            {path: `/customer/client/detail`, name: '客户详情', component: () => import('../views/h5/customer/client/ClientDetail.vue')},
                            {path: `/customer/external`, name: '联系人列表', component: () => import('../views/h5/customer/external/ExternalList.vue')},
                            {path: `/customer/external/detail`, name: '联系人详情', component: () => import('../views/h5/customer/external/ExternalDetail.vue')},
                            {path: `/customer/records`, name: '跟进记录', component: () => import('../views/h5/customer/records/RecordList.vue')},
                            {path: `/customer/records/detail`, name: '跟进记录详情', component: () => import('../views/h5/customer/records/RecordDetail.vue')},
                        ]
                    },
                    {
                        path: '/exhibit',
                        name: "商品陈列",
                        component: {render: c => c("router-view")},
                        children: [
                            {path: `/exhibit/project`, name: '陈列立项', component: () => import('../views/h5/exhibit/project/ProjectList.vue')},
                            {path: `/exhibit/project/detail`, name: '陈列立项详情', component: () => import('../views/h5/exhibit/project/ProjectDetail.vue')},
                            {path: `/exhibit/projectCheck`, name: '陈列审批', component: () => import('../views/h5/exhibit/project/CheckList.vue')},
                            {path: `/exhibit/projectCheck/detail`, name: '陈列审批查看详情', component: () => import('../views/h5/exhibit/project/CheckDetail.vue')},
                            {path: `/exhibit/clientCheck`, name: '提报审批', component: () => import('../views/h5/exhibit/projectClient/ProjectClientCheckList.vue')},
                            {path: `/exhibit/clientCheck/detail`, name: '提报审批查看详情', component: () => import('../views/h5/exhibit/projectClient/ProjectClientCheckDetail.vue')},
                            {path: `/exhibit/collectCheck`, name: '采集审批', component: () => import('../views/h5/exhibit/collect/GatherCheckList')},
                            {path: `/exhibit/collectCheck/detail`, name: '采集审批查看详情', component: () => import('../views/h5/exhibit/collect/GatherCheckDetail.vue')},
                            {path: `/exhibit/view`, name: '汇总', component: () => import('../views/h5/exhibit/view/ViewList.vue')},
                            {path: `/exhibit/view/detail`, name: '汇总详情', component: () => import('../views/h5/exhibit/view/ViewDetail.vue')},
                        ]
                    },
                    {
                        path: '/configure',
                        name: "系统配置",
                        component: {render: c => c("router-view")},
                        children: [
                            {path: `/configure/erpRelation`, name: 'ERP关联', component: () => import('../views/h5/configure/erpRelation/ErpRelation.vue')},
                            {path: `/configure/exhibitCollect`, name: '陈列采集', component: () => import('../views/h5/configure/exhibitCollect/ExhibitCollect.vue')},
                            {path: `/configure/dictionary`, name: '字典管理', component: () => import('../views/h5/configure/dictionary/DictionarySetting')},
                            {path: `/configure/setting`, name: '系统设置', component: () => import('../views/h5/configure/systemSetting/SystemSetting')},
                            {path: `/configure/processgrant`, name: '报销审批流程配置', component: () => import('../views/h5/configure/process/ProcessSettingList')},
                            {path: `/configure/processgrant/detail`, name: '报销审批流程配置详情', component: () => import('../views/h5/configure/process/ProcessSettingDetail')},

                        ]
                    },
                    {
                        path: '/agent',
                        name: "代理商服务",
                        component: {render: c => c("router-view")},
                        children: [
                            {path: `/agent/fee`, name: '客户年费列表', component: () => import('../views/h5/agent/fee/AgentFeeList.vue')},
                            {path: `/agent/fee/detail`, name: '客户年费', component: () => import('../views/h5/agent/fee/AgentFeeDetail.vue')},
                        ]
                    },
                    {
                        path: '/statement',
                        name: "对账管家",
                        component: {render: c => c("router-view")},
                        children: [
                            {path: `/statement/paybill`, name: '对账列表', component: () => import('../views/h5/statement/paybill/PayBillList')},
                            {path: `/statement/paybill/detail`, name: '对账单详情', component: () => import('../views/h5/statement/paybill/PayBillDetail')},
                            {path: `/statement/erpbill`, name: '账单列表', component: () => import('../views/h5/statement/erpbill/ErpBillList')},
                            {path: `/statement/branch`, name: '分支机构', component: () => import('../views/h5/statement/branch/BranchOrg')},
                        ]
                    },
                    {
                        path: '/statementiou',
                        name: "欠条",
                        component: {render: c => c("router-view")},
                        children: [
                            {path: `/statementiou/iou`, name: '欠条管理', component: () => import('../views/h5/statement/iou/ErpBillList')},
                        ]
                    },
                    {
                        path: '/order',
                        name: "供应链-订单",
                        component: {render: c => c("router-view")},
                        children: [
                            {path: `/order/sale`, name: '销售订单', component: () => import('../views/h5/order/sale/SaleList')},
                            {path: `/order/sale/detail`, name: '销售订单详情', component: () => import('../views/h5/order/sale/SaleDetail')},
                            {path: `/order/procure`, name: '采购订单', component: () => import('../views/h5/order/procure/ProcureList')},
                            {path: `/order/procure/detail`, name: '采购订单详情', component: () => import('../views/h5/order/procure/ProcureDetail')},
                        ]
                    },
                    {
                        path: '/chain',
                        name: "供应链-上下游",
                        component: {render: c => c("router-view")},
                        children: [
                            {path: `/chain/supply`, name: '供应商', component: () => import('../views/h5/chain/supply/SupplyList')},
                            {path: `/chain/sale`, name: '经销商', component: () => import('../views/h5/chain/sale/SaleList')},
                        ]
                    },
                    {
                        path: '/product',
                        name: "供应链-商品",
                        component: {render: c => c("router-view")},
                        children: [
                            // {path: `/product/product`, name: '商品管理', component: () => import('../views/h5/product/product/ProductList')},
                            // {path: `/product/product/detail`, name: '商品详情', component: () => import('../views/h5/product/product/ProductDetail')},
                            // {path: `/product/relation`, name: '商品关联', component: () => import('../views/h5/product/relation/RelationList')},
                            // {path: `/product/relation/detail`, name: '商品关联详情', component: () => import('../views/h5/product/relation/RelationDetail')},
                            // {path: `/product/attribute`, name: '商品属性', component: () => import('../views/h5/product/attribute/AttributeList')},


                            {path: `/product/product`, name: '商品列表', component: () => import('../views/h5/product/product/ProductList')},
                            {path: `/product/product/detail`, name: '商品详情', component: () => import('../views/h5/product/product/ProductDetail')},
                            {path: `/product/attribute`, name: '商品属性', component: () => import('../views/h5/product/attribute/AttributeMerchant')},
                            // {path: `/product/support`, name: '供应商列表', component: () => import('../views/h5/product/support/ProductSupport')},
                            // {path: `/product/brand`, name: '品牌', component: () => import('../views/h5/product/brade/ProductBrade')},
                        ]
                    },
                    {
                        path: '/manage',
                        name: "企业管理",
                        component: {render: c => c("router-view")},
                        children: [
                            {path: `/manage/user`, name: '用户', component: () => import('../views/h5/merchantManage/user/User')},
                            {path: `/manage/role`, name: '角色', component: () => import('../views/h5/merchantManage/role/Role')},
                            // {path: `/manage/role2`, name: '角色', component: () => import('../views/h5/merchantManage/role/Role_old')},
                            {path: `/manage/weCom`, name: '企业微信', component: () => import('../views/h5/merchantManage/weCom/WeCom')},
                            {path: `/manage/accountOrder`, name: '账号订单', component: () => import('../views/h5/merchantManage/accountOrder/AccountOrder')},
                            {path: `/manage/checkin`, name: '考勤记录', component: () => import('../views/h5/merchantManage/checkin/Checkin')},
                            {path: `/manage/processcheck`, name: '流程审批', component: () => import('../views/h5/merchantManage/process/ProcessCheck')},
                            {path: `/manage/portalSetting`, name: '官网建设', component: () => import('../views/h5/merchantManage/portal/PortalSetting')},
                            {path: `/manage/lottery/detail`, name: '抽奖小游戏', component: () => import('../views/h5/merchantManage/lottery/LotteryDetail')},
                            {path: `/manage/employee`, name: '职员管理', component: () => import('../views/h5/merchantManage/user/EmployeeList')},
                        ]
                    },
                    {
                        path: '/customer-process',
                        name: "自定义流程",
                        component: {render: c => c("router-view")},
                        children: [
                            {path: `/customer-process/list`, name: '自定义流程列表', component: () => import('../views/h5/configure/customerProcess/CustomerProcessList')},
                            {path: `/customer-process/list/detail`, name: '自定义流程详情', component: () => import('../views/h5/configure/customerProcess/CustomerProcessDetail')},
                            /** 洪旺达定制*/
                            {path: `/customer-process/salePromotion`, name: '促销申请表', component: () => import('../views/h5/dzfw/hwd/salePromotion/SalePromotion.vue')},
                            {path: `/customer-process/salePromotion/detail`, name: '促销申请详情', component: () => import('../views/h5/dzfw/hwd/salePromotion/SalePromotionDetail')},
                            {path: `/customer-process/salePromotion/preview`, name: '促销申请详情打印预览', component: () => import('../views/h5/dzfw/hwd/salePromotion/SalePromotionDetailPreview')},
                            {path: `/customer-process/salePromotion/preview2`, name: '促销申请详情打印预览2', component: () => import('../views/h5/dzfw/hwd/salePromotion/SalePromotionDetailPreview2')},
                            {path: `/customer-process/siteCost`, name: '进场费用申请表', component: () => import('../views/h5/dzfw/hwd/siteCost/SiteCostList')},
                            {path: `/customer-process/siteCost/detail`, name: '进场费用申请详情', component: () => import('../views/h5/dzfw/hwd/siteCost/SiteCostDetail')},
                            {path: `/customer-process/siteCost/preview`, name: '进场费用申请打印预览', component: () => import('../views/h5/dzfw/hwd/siteCost/SiteCostDetailPreview')},
                            {path: `/customer-process/salePromotionApply`, name: '促销服务协议', component: () => import('../views/h5/dzfw/hwd/salePromotionApply/SalePromotionApplyList')},
                            {path: `/customer-process/salePromotionApply/detail`, name: '促销服务协议详情', component: () => import('../views/h5/dzfw/hwd/salePromotionApply/SalePromotionApplyDetail')},
                            {path: `/customer-process/salePromotionApply/template`, name: '促销服务协议', component: () => import('../views/h5/dzfw/hwd/salePromotionApply/SalePromotionApplyTemplate')},
                            {path: `/customer-process/salePromotionApply/preview`, name: '促销服务协议', component: () => import('../views/h5/dzfw/hwd/salePromotionApply/SalePromotionApplyPreview')},
                            {path: `/customer-process/costApply`, name: '费用申请单', component: () => import('../views/h5/dzfw/hwd/costApply/CostApplyList')},
                            {path: `/customer-process/costApply/detail`, name: '促销服务协议', component: () => import('../views/h5/dzfw/hwd/costApply/CostApplyDetail')},
                            {path: `/customer-process/costApply/preview`, name: '促销服务协议', component: () => import('../views/h5/dzfw/hwd/costApply/CostApplyPreview')},
                            {path: `/customer-process/receive`, name: '促销服务协议', component: () => import('../views/h5/dzfw/hwd/receive/HwdReceiveList.vue')},
                            {path: `/customer-process/receive/detail`, name: '促销服务协议', component: () => import('../views/h5/dzfw/hwd/receive/HwdReceiveDetail.vue')},
                        ]
                    },
                    {
                        path: '/shlj',
                        name: "上海珑京定制",
                        component: {render: c => c("router-view")},
                        children: [
                            {path: `/shlj/outStock`, name: '珑京出库同步', component: () => import('../views/h5/dzfw/shlj/outStock/SHLJOutStock')},
                            {path: `/shlj/stockInfo`, name: '珑京库存信息', component: () => import('../views/h5/dzfw/shlj/stockInfo/SHLJStockInfo')},
                            {path: `/shlj/logs`, name: '珑京同步日志', component: () => import('../views/h5/dzfw/shlj/logs/SHLJLogsInfo')},
                            {path: `/shlj/btype`, name: '珑京往来单位', component: () => import('../views/h5/dzfw/shlj/btype/SHLJBtype')},
                            {path: `/shlj/ptype`, name: '珑京商品管理', component: () => import('../views/h5/dzfw/shlj/ptype/SHLJPtype')},

                        ]
                    },
                ]
            }
        ]
    },
    ];
export default routers;